import { PolySynth, Synth as ToneSynth, AMSynth, FMSynth, MonoSynth } from 'tone'
import AbstractInstrument                                             from 'framework/models/AbstractInstrument'

const synths = {
  Synth: ToneSynth,
  AMSynth,
  FMSynth,
  MonoSynth,
}

export default class Synth extends AbstractInstrument {
  constructor(name) {
    super(name)
    this.handler = new PolySynth(synths[name], {
      detune:         0,
      oscillator:     {
        type: 'triangle',
      },
      filter:         {
        Q:       6,
        type:    'lowpass',
        rolloff: -24,
      },
      envelope:       {
        attack:  0.01,
        decay:   1,
        sustain: 0.01,
        release: 1,
      },
      filterEnvelope: {
        attack:        0.06,
        decay:         0.2,
        sustain:       0.5,
        release:       2,
        baseFrequency: 200,
        octaves:       7,
        exponent:      2,
      },
    }).connect(this.fft)
  }

  play({ notes = [], dur = '4n', time = '+0.05', velocity = 1 }) {
    this.handler.triggerAttackRelease(notes, dur, time, velocity)
  }

  setVolume(val) {
    this.handler.volume.value = val
  }
}

import { Sampler }   from 'tone'
import AbstractInstrument from 'framework/models/AbstractInstrument'

export default class Instrument extends AbstractInstrument {
  constructor(name) {
    super(name)
    this.handler              = new Sampler()
    this.handler.volume.value = -15
    this.handler.connect(this.fft)
    this.handler.attack  = 0.05
    this.handler.release = 0.3
  }

  play({ notes = [], dur = '4n', time = '+0.05', velocity }) {
    this.handler.triggerAttackRelease(notes, dur, time, velocity)
  }

  triggerAttack({ notes = [], time = '+0.05', velocity = 1 }) {
    this.handler.triggerAttack(notes, time, velocity)
  }

  triggerRelease(notes) {
    this.handler.triggerRelease(notes)
  }

  add(note, source) {
    this.handler.add(note, source)
    this.loadedFiles.push(note)
  }

  setVolume(val) {
    this.volume = val
    if (this.handler) {
      this.handler.volume.value = val
    }
  }
}

import firebase from 'firebase/app'
import 'firebase/storage'

import { context }                                               from 'tone'
import { DRUMS_PLAYER, AVAILABLE_DRUM_SOUNDS, AVAILABLE_SYNTHS } from 'framework/resources/framework-constants'
import insFiles
                                                                 from 'framework/resources/json/instrument-files-ref.json'
import Percussion                                                from 'framework/models/Percussion'
import Instrument                                                from 'framework/models/Instrument'
import Synth                                                     from 'framework/models/Synth'
import store                                                     from 'src/store'
import { getNotesInRange }                                       from 'framework/helpers/note-art-helpers'

const storage = firebase.storage()

class AudioManager {
  constructor() {
    this.fileLoadCounter = 0
  }

  /**
   * Add instrument to the audio manager
   * @param name
   * @returns {Promise<void>}
   */
  async add(name) {
    if( !this[name]) {
      if(name === DRUMS_PLAYER) {
        this[name] = new Percussion(name)
        AVAILABLE_DRUM_SOUNDS.forEach(fileName => this.loadFile(name, fileName))
      } else if(AVAILABLE_SYNTHS.includes(name)) {
        this[name] = new Synth(name)
      } else {
        this[name] = new Instrument(name)
        await this.loadNotes(name)
      }
    }
  }

  async loadNotes(name, baseNote = 'C2', range = 36) {
    if(AVAILABLE_SYNTHS.includes(name)) {
      // Synths don't need to load notes...
      return
    }
    const instrument    = this[name]
    const existingFiles = insFiles[name]
    const notes         = [...Object.keys(getNotesInRange(baseNote, range))]
      .filter(note => existingFiles.includes(note))
      .filter(note => !instrument.loadedFiles.includes(note))
    if(notes.length) {
      this.fileLoadCounter = notes.length
      await store.dispatch('view/setIsDownloading', true)
      try {
        await Promise.all(notes.map(fileName => this.loadFile(name, fileName)))
      } catch (e) {
        console.log(e)
      }
      await store.dispatch('view/setIsDownloading', false)
    }
  }

  async loadFile(name, fileName) {
    let path
    const model = this[name]
    if(name === 'drums') {
      path = `audio/${ name.toLowerCase() }/${ model.type }/${ fileName }.mp3`
    } else {
      path = `audio/${ name.toLowerCase() }/${ fileName }.mp3`
    }
    const buffer      = await this.getAudioBuffer(path)
    const audioBuffer = await context.decodeAudioData(buffer)
    this.fileLoadCounter--
    return model.add(fileName, audioBuffer)
  }

  async getAudioBuffer(path) {
    const cached = await this.getBufferFromCache(path)
    return cached || await this.getBufferFromFirebase(path)
  }

  async getBufferFromFirebase(path) {
    const ref = storage.ref()
    const url = await ref.child(path).getDownloadURL()
    const res = await fetch(url)
    await this.saveBufferToCache(res, path)
    return await res.clone().arrayBuffer()
  }

  async saveBufferToCache(res, path) {
    if('caches' in window) {
      const cache = await caches.open('audio')
      await cache.put(path, res.clone())
    }
  }

  async getBufferFromCache(path) {
    if('caches' in window) {
      const cached = await caches.match(path)
      return cached ? await cached.arrayBuffer() : null
    }
  }

  setVolume(name, val) {
    if(this[name]) {
      this[name].setVolume(val)
    }
  }
}

const audioManager = new AudioManager()

export default audioManager

import { Players }                 from 'tone'
import { DRUM_PITCHES_TO_PLAYER_NAMES } from 'framework/magenta/constants'
import AbstractInstrument               from 'framework/models/AbstractInstrument'

export default class Percussion extends AbstractInstrument {
  constructor(name, type = 'hiphop') {
    super(name)
    this._type           = type
    this.handler         = new Players()
    this.handler.fadeIn  = 0.01
    this.handler.fadeOut = 0.3
    this.handler.connect(this.fft)
    return this
  }

  get type() {
    return this._type
  }

  set type(type) {
    this._type = type
  }

  play({ notes = [], time = '+0.05', velocity = 1 }) {
    notes.forEach(note => {
      if (typeof note === 'number') {
        note = DRUM_PITCHES_TO_PLAYER_NAMES.get(note)
      }
      this.handler.player(note).volume.value = velocity
      this.handler.player(note).start(time)
    })
  }

  add(file, source) {
    this.handler.add(file, source)
    this.loadedFiles.push(file)
  }

  setVolume(val) {
    this.handler.volume.value = val
  }
}

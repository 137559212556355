export const DEFAULT_QUARTERS_PER_MINUTE = 120.0
// 4/4 music sampled at 4 steps per quarter note.
export const DEFAULT_STEPS_PER_BAR       = 16
export const DEFAULT_STEPS_PER_QUARTER   = 4

// Default absolute quantization.
export const DEFAULT_STEPS_PER_SECOND = 100

export const DEFAULT_VELOCITY          = 80
export const DEFAULT_PROGRAM           = 0
export const DEFAULT_TICKS_PER_QUARTER = 220
export const DEFAULT_CHANNEL           = 0
export const DRUM_CHANNEL              = 9
export const NON_DRUM_CHANNELS         = [0, 1, 2, 3, 4, 5, 6, 7, 8, 10, 11, 12, 13, 14, 15]

// Velocity-related constants.
export const MIN_MIDI_VELOCITY = 0
export const MAX_MIDI_VELOCITY = 127
export const MIDI_VELOCITIES   = MAX_MIDI_VELOCITY - MIN_MIDI_VELOCITY + 1

// Pitch-related constants.
export const NO_CHORD          = 'N.C.'
export const NUM_PITCH_CLASSES = 12
export const MIN_MIDI_PITCH    = 0
export const MAX_MIDI_PITCH    = 127
export const MIDI_PITCHES      = MAX_MIDI_PITCH - MIN_MIDI_PITCH + 1
export const MIN_PIANO_PITCH   = 21
export const MAX_PIANO_PITCH   = 108
export const MIN_DRUM_PITCH    = 35
export const MAX_DRUM_PITCH    = 81

// Program-related constants.
export const MIN_MIDI_PROGRAM = 0
export const MAX_MIDI_PROGRAM = 127

// Click-track constants.
export const LO_CLICK_PITCH = 89
export const HI_CLICK_PITCH = 90
export const LO_CLICK_CLASS = 9
export const HI_CLICK_CLASS = 10

export const DEFAULT_DRUM_PITCH_CLASSES = [
  // bass drum
  [36, 35],
  // snare drum
  [38, 27, 28, 31, 32, 33, 34, 37, 39, 40, 56, 65, 66, 75, 85],
  // closed hi-hat
  [42, 44, 54, 68, 69, 70, 71, 73, 78, 80],
  // open hi-hat
  [46, 67, 72, 74, 79, 81],
  // low tom
  [45, 29, 41, 61, 64, 84],
  // mid tom
  [48, 47, 60, 63, 77, 86, 87],
  // high tom
  [50, 30, 43, 62, 76, 83],
  // crash cymbal
  [49, 55, 57, 58],
  // ride cymbal
  [51, 52, 53, 59, 82],
]

export const DRUM_SEQS = [
  {
    notes:               [
      { pitch: 36, quantizedStartStep: 0 }, { pitch: 42, quantizedStartStep: 2 },
      { pitch: 36, quantizedStartStep: 4 }, { pitch: 42, quantizedStartStep: 6 },
      { pitch: 36, quantizedStartStep: 8 }, { pitch: 42, quantizedStartStep: 10 },
      { pitch: 36, quantizedStartStep: 12 }, { pitch: 42, quantizedStartStep: 14 },
      { pitch: 36, quantizedStartStep: 16 }, { pitch: 36, quantizedStartStep: 24 },
      { pitch: 36, quantizedStartStep: 28 }, { pitch: 42, quantizedStartStep: 30 },
    ],
    quantizationInfo:    { stepsPerQuarter: 4 },
    totalQuantizedSteps: 32,
  },
  {
    notes:               [
      { pitch: 36, quantizedStartStep: 0 }, { pitch: 38, quantizedStartStep: 0 },
      { pitch: 42, quantizedStartStep: 0 }, { pitch: 46, quantizedStartStep: 0 },
      { pitch: 42, quantizedStartStep: 2 }, { pitch: 42, quantizedStartStep: 3 },
      { pitch: 42, quantizedStartStep: 4 }, { pitch: 50, quantizedStartStep: 4 },
      { pitch: 36, quantizedStartStep: 6 }, { pitch: 38, quantizedStartStep: 6 },
      { pitch: 42, quantizedStartStep: 6 }, { pitch: 45, quantizedStartStep: 6 },
      { pitch: 36, quantizedStartStep: 8 }, { pitch: 42, quantizedStartStep: 8 },
      { pitch: 46, quantizedStartStep: 8 }, { pitch: 42, quantizedStartStep: 10 },
      { pitch: 48, quantizedStartStep: 10 }, { pitch: 50, quantizedStartStep: 10 },
      { pitch: 36, quantizedStartStep: 12 }, { pitch: 38, quantizedStartStep: 12 },
      { pitch: 42, quantizedStartStep: 12 }, { pitch: 48, quantizedStartStep: 12 },
      { pitch: 50, quantizedStartStep: 13 }, { pitch: 42, quantizedStartStep: 14 },
      { pitch: 45, quantizedStartStep: 14 }, { pitch: 48, quantizedStartStep: 14 },
      { pitch: 36, quantizedStartStep: 16 }, { pitch: 38, quantizedStartStep: 16 },
      { pitch: 42, quantizedStartStep: 16 }, { pitch: 46, quantizedStartStep: 16 },
      { pitch: 49, quantizedStartStep: 16 }, { pitch: 42, quantizedStartStep: 18 },
      { pitch: 42, quantizedStartStep: 19 }, { pitch: 42, quantizedStartStep: 20 },
      { pitch: 50, quantizedStartStep: 20 }, { pitch: 36, quantizedStartStep: 22 },
      { pitch: 38, quantizedStartStep: 22 }, { pitch: 42, quantizedStartStep: 22 },
      { pitch: 45, quantizedStartStep: 22 }, { pitch: 36, quantizedStartStep: 24 },
      { pitch: 42, quantizedStartStep: 24 }, { pitch: 46, quantizedStartStep: 24 },
      { pitch: 42, quantizedStartStep: 26 }, { pitch: 48, quantizedStartStep: 26 },
      { pitch: 50, quantizedStartStep: 26 }, { pitch: 36, quantizedStartStep: 28 },
      { pitch: 38, quantizedStartStep: 28 }, { pitch: 42, quantizedStartStep: 28 },
      { pitch: 42, quantizedStartStep: 30 }, { pitch: 48, quantizedStartStep: 30 },
    ],
    quantizationInfo:    { stepsPerQuarter: 4 },
    totalQuantizedSteps: 32,
  },
  {
    notes:               [
      { pitch: 38, quantizedStartStep: 0 }, { pitch: 42, quantizedStartStep: 0 },
      { pitch: 42, quantizedStartStep: 2 }, { pitch: 42, quantizedStartStep: 4 },
      { pitch: 36, quantizedStartStep: 6 }, { pitch: 38, quantizedStartStep: 6 },
      { pitch: 42, quantizedStartStep: 6 }, { pitch: 45, quantizedStartStep: 6 },
      { pitch: 36, quantizedStartStep: 8 }, { pitch: 42, quantizedStartStep: 8 },
      { pitch: 42, quantizedStartStep: 10 }, { pitch: 38, quantizedStartStep: 12 },
      { pitch: 42, quantizedStartStep: 12 }, { pitch: 45, quantizedStartStep: 12 },
      { pitch: 36, quantizedStartStep: 14 }, { pitch: 42, quantizedStartStep: 14 },
      { pitch: 46, quantizedStartStep: 14 }, { pitch: 36, quantizedStartStep: 16 },
      { pitch: 42, quantizedStartStep: 16 }, { pitch: 42, quantizedStartStep: 18 },
      { pitch: 38, quantizedStartStep: 20 }, { pitch: 42, quantizedStartStep: 20 },
      { pitch: 45, quantizedStartStep: 20 }, { pitch: 36, quantizedStartStep: 22 },
      { pitch: 42, quantizedStartStep: 22 }, { pitch: 36, quantizedStartStep: 24 },
      { pitch: 42, quantizedStartStep: 24 }, { pitch: 38, quantizedStartStep: 26 },
      { pitch: 42, quantizedStartStep: 26 }, { pitch: 45, quantizedStartStep: 26 },
      { pitch: 42, quantizedStartStep: 28 }, { pitch: 45, quantizedStartStep: 28 },
      { pitch: 36, quantizedStartStep: 30 }, { pitch: 42, quantizedStartStep: 30 },
      { pitch: 45, quantizedStartStep: 30 },
    ],
    quantizationInfo:    { stepsPerQuarter: 4 },
    totalQuantizedSteps: 32,
  },
  {
    notes:               [
      { pitch: 50, quantizedStartStep: 4 }, { pitch: 50, quantizedStartStep: 20 },
    ],
    quantizationInfo:    { stepsPerQuarter: 4 },
    totalQuantizedSteps: 32,
  },
]

DRUM_SEQS.map(s => s.notes.map(n => {
  n.isDrum           = true
  n.quantizedEndStep = n.quantizedStartStep + 1
}))

export const DRUM_PITCHES = [36, 38, 42, 46, 45, 48, 50, 49, 51]

export const DRUM_PLAYERS_TO_PITCHES = new Map([
  ['kick', 36],
  ['snare', 38],
  ['hihat-closed', 42],
  ['hihat-open', 46],
  ['tom-low', 45],
  ['tom-mid', 48],
  ['tom-high', 50],
  ['clap', 49],
  ['ride', 51],
])

export const DRUM_PITCHES_TO_PLAYER_NAMES = new Map([
  [36, 'kick'],
  [38, 'snare'],
  [42, 'hihat-closed'],
  [46, 'hihat-open'],
  [45, 'tom-low'],
  [48, 'tom-mid'],
  [50, 'tom-high'],
  [49, 'clap'],
  [51, 'ride'],
])
import { FFT } from 'tone/build/esm/component/analysis/FFT'

export default class Instrument {
  constructor(name) {
    this._name       = name
    this.loadedFiles = []
    this.fft         = new FFT(512).toDestination()
  }

  get name() {
    return this._name
  }

  getFFTData() {
    return this.fft.getValue()
  }

  getFFTSize() {
    return this.fft.size
  }

  connect(destination) {
    this.handler.connect(destination)
  }

  disconnect() {
    this.handler.disconnect()
  }
}
